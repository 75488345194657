<template>
    <div>
        <div class="d-flex justify-content-between align-items-top mb-3">
            <test-records-filterbar
                :query-params="activeQueryParams"
                class="w-100"
            />
            <teleport
                v-if="hasAnyPermission('test.export')"
                :disabled="isDesktop"
                defer
                to="#page-header-action"
            >
                <export-menu
                    :active-query-params="activeQueryParams"
                    :no-item-selected-tooltip="
                        trans(
                            'test_records.overview.export.tooltip.no_item_selected',
                        )
                    "
                    :non-query-filters="{ assetId: asset.id }"
                    :selection="selection"
                    routeName="tests.export"
                />
            </teleport>
        </div>
        <card>
            <template #header>
                <custom-card-header
                    :href="$route('assets.tests.index', asset)"
                    :label="trans('general.action.show_all')"
                    :show-action="hasActiveFilters"
                    :title="trans('test_records.overview.header')"
                />
            </template>
            <row v-if="isTestOverdue">
                <column>
                    <alert
                        class="d-flex align-items-center mb-1"
                        color="danger"
                        style="gap: 5px; line-height: 1rem"
                    >
                        <exclamation-mark type="filled" />
                        <div style="line-height: 1rem">
                            <span>{{
                                trans('test.overview.alert.overdue_test')
                            }}</span
                            >&nbsp;
                            <span v-if="readableCadence">{{
                                trans(
                                    'test.overview.alert.overdue_test.cadence',
                                    {
                                        cadence: readableCadence,
                                    },
                                )
                            }}</span>
                        </div>
                    </alert>
                </column>
            </row>

            <row>
                <column>
                    <data-table
                        :actions="actions"
                        :columns="fields"
                        :items="tests"
                        :no-items-view="noItemsView"
                        :query-params="activeQueryParams"
                        :selection-enabled="selectionEnabled"
                        @row-clicked="showDetails"
                    >
                        <template #date="{ item }">
                            <td class="align-top">
                                <div class="text-muted">
                                    <div class="text-nowrap">
                                        {{
                                            $filters.fullDate(
                                                item.date,
                                                currentLocale,
                                            )
                                        }}
                                    </div>
                                    <small>
                                        <icon icon="cil-clock" />
                                        {{
                                            $filters.time(
                                                item.date,
                                                currentLocale,
                                            )
                                        }}</small
                                    >
                                </div>
                            </td>
                        </template>

                        <template #user="{ item }">
                            <td class="align-top">
                                <user-information
                                    v-if="item.user"
                                    :info="item.user"
                                />
                                <span v-else>-</span>
                            </td>
                        </template>

                        <template #result="{ item }">
                            <td class="align-middle text-center">
                                <icon
                                    v-if="item.resolution.value === 'success'"
                                    :key="item.id"
                                    v-c-tooltip="{
                                        content: trans(
                                            'test_records.overview.tooltip.result.successful',
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    icon="cil-check-alt"
                                    size="lg"
                                />
                                <icon
                                    v-else
                                    :key="item.id"
                                    v-c-tooltip="{
                                        content: trans(
                                            'test_records.overview.tooltip.result.failed',
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    class="text-danger"
                                    icon="cil-warning"
                                />
                            </td>
                        </template>

                        <template #report="{ item }">
                            <td class="align-top">
                                <div
                                    :class="{
                                        'text-max-2-lines':
                                            !showFullDescription(item),
                                    }"
                                    class="text-break"
                                    style="-webkit-line-clamp: 3"
                                >
                                    {{ item.description }}
                                </div>
                            </td>
                        </template>

                        <template #attachments="{ item }">
                            <td class="align-top">
                                <FileList
                                    :attachments="item.attachments"
                                    :can-read="
                                        hasAnyPermission([
                                            'test.attachment.index',
                                        ])
                                    "
                                    :inline="true"
                                    :readonly="true"
                                />
                            </td>
                        </template>

                        <template #qr_code_scanned="{ item }">
                            <td class="align-middle text-center">
                                <icon
                                    v-if="item.qr_code_scanned_at"
                                    v-c-tooltip="{
                                        content: trans(
                                            'test_records.overview.tooltip.qr_code_scanned',
                                        ),
                                    }"
                                    icon="cil-check-alt"
                                    size="lg"
                                />
                                <icon
                                    v-else
                                    v-c-tooltip="{
                                        content: trans(
                                            'test_records.overview.tooltip.qr_code_not_scanned',
                                        ),
                                    }"
                                    icon="cil-x"
                                    size="lg"
                                />
                            </td>
                        </template>
                    </data-table>
                </column>
            </row>
        </card>
        <jet-confirmation-modal
            :show="showConfirmDeletionDialog"
            type="danger"
            @close="recordToDelete = null"
        >
            <template #title>
                {{ trans('test_records.overview.confirmation.delete.title') }}
            </template>

            <template #content>
                {{ trans('test_records.overview.confirmation.delete.content') }}
            </template>

            <template #footer>
                <slot name="footer">
                    <jet-button
                        :isProcessing="isDeletingTestRecord"
                        color="danger"
                        @click.stop="deleteTestRecord(recordToDelete)"
                    >
                        {{ trans('general.action.delete') }}
                    </jet-button>
                </slot>
            </template>
        </jet-confirmation-modal>
    </div>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import ConfirmedDelete from '@/Components/ConfirmedDelete.vue';
import FileList from '@/Components/FileList.vue';
import UserInformation from '@/Components/UserInformation.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import DataTable from '@/Components/DataTable.vue';
import Card from '@/Components/Card.vue';
import Alert from '@/Components/Alert.vue';
import Badge from '@/Components/Badge.vue';
import ExclamationMark from '../../icons/ExclamationMark.vue';
import TestRecordsFilterbar from '@/Pages/TestRecords/TestRecordsFilterbar.vue';
import Pagination from '@/Components/Pagination.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { usePage } from '@inertiajs/vue3';
import ExportMenu from '@/Components/ExportMenu.vue';
import useSelectedItems from '@/Utils/UseSelectedItems';
import { isDesktop } from '@/Utils/UseMedia';
import { computed } from 'vue';
import { trans } from '@/mixins/i18n.js';

export default {
    components: {
        ExportMenu,
        Pagination,
        TestRecordsFilterbar,
        ExclamationMark,
        Badge,
        Alert,
        Card,
        DataTable,
        JetButton,
        JetConfirmationModal,
        CustomCardHeader,
        ConfirmedDelete,
        FileList,
        UserInformation,
    },

    setup() {
        const page = usePage();
        const testRecordsMeta = computed(() => page.props.tests?.meta || {});
        const selection = useSelectedItems(
            'asset-test-records',
            testRecordsMeta,
        );

        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                start: null,
                end: null,
                types: [],
                users: [],
                results: [],
                sort: { column: 'date', asc: false },
            },
            route: route('assets.tests.index', page.props.asset),
            options: {
                only: ['tests'],
            },
            additionalData: () => ({
                selection: selection.getSelectionAsQueryParams(),
            }),
            onLoadSuccess: (data) => {
                selection.setSelection(data.props.tests.meta.selection);
            },
        });

        return {
            activeQueryParams,
            isDesktop,
            selection,
        };
    },

    data() {
        return {
            openReports: [],
            fields: [
                {
                    key: 'date',
                    label: this.trans('test_records.overview.cell.date'),
                },
                {
                    key: 'qr_code_scanned',
                    label: this.trans(
                        'test_records.overview.cell.qr_code_scanned',
                    ),
                },
                {
                    key: 'result',
                    label: this.trans('test_records.overview.cell.result'),
                },
                {
                    key: 'report',
                    label: this.trans('test_records.overview.cell.report'),
                },
                {
                    key: 'user',
                    label: this.trans('test_records.overview.cell.user'),
                },
                {
                    key: 'attachments',
                    label: this.trans('test_records.overview.cell.attachments'),
                },
            ],
            noItemsView: {
                noItems: this.trans('test_records.overview.results.empty'),
            },
            selectedActivity: null,
            showActivityDetails: false,
            transformers: {
                scheduled_for: (value) => {
                    return this.$filters.fullDate(value, this.currentLocale);
                },
            },
            actions: [
                {
                    permissions: ['test.delete'],
                    tooltip: this.trans('general.action.delete'),
                    type: 'danger',
                    click: this.setRecordToDelete,
                    icon: 'cil-trash',
                },
            ],
            recordToDelete: null,
            isLoadingTestRecords: false,
            isDeletingTestRecord: false,
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
        tests() {
            return this.$page.props.tests;
        },
        hasActiveFilters() {
            return this.$page.props.is_filter_active;
        },
        isTestOverdue() {
            return this.$page.props.isTestOverdue;
        },
        cadenceConfig() {
            return this.$page.props.cadenceConfig;
        },
        readableCadence() {
            return !!this.cadenceConfig
                ? `${this.cadenceConfig.value} ${this.cadenceConfig.unit}`
                : null;
        },
        showConfirmDeletionDialog() {
            return !!this.recordToDelete;
        },
        selectionEnabled() {
            return this.hasAnyPermission('test.export')
                ? 'asset-test-records'
                : false;
        },
    },

    methods: {
        trans,
        deleteTestRecord(item) {
            if (!item) {
                return;
            }

            this.isDeletingTestRecord = true;

            this.$inertia.delete(
                this.route('assets.tests.destroy', {
                    asset: this.asset,
                    test: item,
                }),
                {
                    preserveState: true,
                    onSuccess: () => {
                        this.recordToDelete = null;
                    },
                    onFinish: () => {
                        this.isDeletingTestRecord = false;
                    },
                },
            );
        },
        showFullDescription(item) {
            return this.openReports.includes(item.id);
        },
        showDetails(item) {
            window.open(route('tests.show', item.id), '_blank').focus();
        },
        setRecordToDelete(item) {
            this.recordToDelete = item;
        },
    },
};
</script>
