<template>
    <section>
        <slot v-if="$slots.title" name="title" />

        <multi-select-dropdown
            v-if="hasAnyPermission('asset.update')"
            v-model="form.owners"
            :horizontal="horizontal"
            :invalid-feedback="form.errors.owners"
            :is-form-field="true"
            :label="trans('stakeholders.edit.field.owners.label')"
            :options="owners"
            :show-searchbar="true"
            :show-select-all-button="false"
            footer-classes="bg-gray-light"
        >
            <template #footer="{ hideElement }">
                <button-add-new
                    v-if="hasAnyPermission('owner.create')"
                    @click.native="addNewOwner(hideElement)"
                />
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-if="hasAnyPermission('asset.update')"
            v-model="form.operators"
            :horizontal="horizontal"
            :invalid-feedback="form.errors.operators"
            :is-form-field="true"
            :label="trans('stakeholders.edit.field.operators.label')"
            :options="operators"
            :show-searchbar="true"
            :show-select-all-button="false"
            footer-classes="bg-gray-light"
        >
            <template #footer="{ hideElement }">
                <button-add-new
                    v-if="hasAnyPermission('operator.create')"
                    @click.native="addNewOperator(hideElement)"
                />
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-if="hasAnyPermission('asset.update')"
            v-model="form.maintainers"
            :horizontal="horizontal"
            :invalid-feedback="form.errors.maintainers"
            :is-form-field="true"
            :label="trans('stakeholders.edit.field.maintainers.label')"
            :options="maintainers"
            :show-searchbar="true"
            :show-select-all-button="false"
            footer-classes="bg-gray-light"
        >
            <template #footer="{ hideElement }">
                <button-add-new
                    v-if="hasAnyPermission('maintainer.create')"
                    @click.native="addNewMaintainer(hideElement)"
                />
            </template>
        </multi-select-dropdown>
    </section>
</template>
<script setup>
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import { usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import { trans } from '@/mixins/i18n.js';
import { defaultSla } from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerSlaFormModal.vue';

const props = defineProps({
    form: Object,
    horizontal: { type: Boolean, default: false },
});
const componentKey = ref(0);

const page = usePage();

const owners = computed(() =>
    (page.props.owners?.data ?? []).map((owner) => ({
        label: owner.name,
        value: owner.id,
    })),
);
const operators = computed(() =>
    (page.props.operators?.data ?? []).map((operator) => ({
        label: operator.name,
        value: operator.id,
    })),
);
const maintainers = computed(() =>
    (page.props.maintainers?.data ?? []).map((maintainer) => ({
        label: maintainer.name,
        value: maintainer.id,
    })),
);

const slaOverridden = computed(() => {
    return ['high', 'medium', 'low'].some((priority) => {
        return (
            props.form.serviceLevelAgreement.timeToRespond[priority].unit !==
                defaultSla.timeToRespond[priority].unit ||
            props.form.serviceLevelAgreement.timeToRespond[priority].value !==
                defaultSla.timeToRespond[priority].value ||
            props.form.serviceLevelAgreement.timeToResolve[priority].unit !==
                defaultSla.timeToResolve[priority].unit ||
            props.form.serviceLevelAgreement.timeToResolve[priority].value !==
                defaultSla.timeToResolve[priority].value
        );
    });
});

const restoreDefaultServiceLevelAgreement = () => {
    ['timeToRespond', 'timeToResolve'].forEach((key) => {
        ['high', 'medium', 'low'].forEach((priority) => {
            props.form.serviceLevelAgreement[key][priority].value =
                defaultSla[key][priority].value;
            props.form.serviceLevelAgreement[key][priority].unit =
                defaultSla[key][priority].unit;
        });
    });
};
</script>
