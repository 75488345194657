<template>
    <section
        v-if="maintainer.serviceLevelAgreements?.length > 0"
        :class="{ 'mt-3': !collapsed }"
        class="sla"
    >
        <div :class="{ collapsed }" class="grid">
            <div v-if="!isTablet" class="grid-header">
                <div class="grid-cell">
                    {{ trans('maintainers.overview.sla.asset_type.title') }}
                </div>
                <div class="grid-cell">
                    {{
                        trans(
                            'maintainers.overview.sla.contracting_authority.title',
                        )
                    }}
                </div>
                <div class="grid-cell">
                    {{ trans('maintainers.sla.agreement_term.title') }}
                </div>
                <div class="grid-cell">
                    <div class="d-flex gap-3">
                        <div class="flex-grow-1" style="flex-basis: 50%">
                            {{ trans('maintainers.sla.time_to_respond.title') }}
                        </div>
                        <div class="flex-grow-1" style="flex-basis: 50%">
                            {{ trans('maintainers.sla.time_to_resolve.title') }}
                        </div>
                    </div>
                </div>
                <div class="grid-cell"></div>
            </div>
            <div
                v-for="sla in maintainer.serviceLevelAgreements"
                :key="sla.id"
                :class="{ highlight: shouldHighlightSla(sla) }"
                class="grid-row-wrapper"
            >
                <div :id="sla.id" class="grid-cell mt-3 mt-md-0">
                    <div class="h6">{{ sla.type.value }}</div>
                    <div class="text-nowrap">
                        {{
                            trans('stakeholders.overview.assets_count.label', {
                                count: sla.affectedAssetsCount || 0,
                            })
                        }}
                    </div>
                    <div class="text-nowrap">
                        {{
                            trans(
                                'stakeholders.overview.locations_count.label',
                                {
                                    count:
                                        sla.affectedTopLevelLocationsCount || 0,
                                },
                            )
                        }}
                    </div>
                    <hr class="d-md-none mb-0" />
                </div>
                <div class="grid-cell pt-0 pb-1 py-md-3">
                    <div class="h6 d-md-none mb-1">
                        {{
                            trans(
                                'maintainers.overview.sla.contracting_authority.title',
                            )
                        }}
                    </div>
                    {{ sla.contractingAuthority?.name || '-' }}
                </div>
                <div class="grid-cell py-0 pt-2 py-md-3">
                    <div class="h6 d-md-none mb-1">
                        {{ trans('maintainers.sla.agreement_term.title') }}
                    </div>
                    <div
                        :class="{ 'text-danger': getExpirationInfo(sla) }"
                        v-html="getExpirationDateRange(sla)"
                    />
                    <div
                        v-if="getExpirationInfo(sla)"
                        class="d-flex align-items-center text-danger text-nowrap gap-1"
                        style="font-size: 13px"
                    >
                        <exclamation-mark type="filled" />
                        {{ getExpirationInfo(sla) }}
                    </div>
                    <hr class="d-md-none mb-0" />
                </div>
                <div class="grid-cell">
                    <div class="d-flex gap-3">
                        <div class="flex-grow-1" style="flex-basis: 50%">
                            <div class="h6 d-md-none mb-1">
                                {{
                                    trans(
                                        'maintainers.sla.time_to_respond.title',
                                    )
                                }}
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <high v-c-tooltip="{ content: 'High' }" />
                                <span
                                    v-if="sla?.timeToRespond?.high.value"
                                    class="text-gray"
                                    >{{
                                        getTimeDurationLabel(
                                            sla.timeToRespond.high,
                                        )
                                    }}</span
                                >
                                <span v-else class="text-gray">-</span>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <medium
                                    v-c-tooltip="{
                                        content: 'Medium',
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                />
                                <span
                                    v-if="sla?.timeToRespond?.medium.value"
                                    class="text-gray"
                                    >{{
                                        getTimeDurationLabel(
                                            sla.timeToRespond.medium,
                                        )
                                    }}</span
                                >
                                <span v-else class="text-gray">-</span>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <low
                                    v-c-tooltip="{
                                        content: 'Low',
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                />
                                <span
                                    v-if="sla?.timeToRespond?.low.value"
                                    class="text-gray"
                                    >{{
                                        getTimeDurationLabel(
                                            sla.timeToRespond.low,
                                        )
                                    }}</span
                                >
                                <span v-else class="text-gray">-</span>
                            </div>
                        </div>
                        <div class="flex-grow-1" style="flex-basis: 50%">
                            <div class="h6 d-md-none mb-1">
                                {{
                                    trans(
                                        'maintainers.sla.time_to_resolve.title',
                                    )
                                }}
                            </div>
                            <div
                                class="d-flex align-items-center gap-2 text-nowrap"
                            >
                                <high v-c-tooltip="{ content: 'High' }" />
                                <span
                                    v-if="sla?.timeToResolve?.high.value"
                                    class="text-gray text-nowrap"
                                    >{{
                                        getTimeDurationLabel(
                                            sla.timeToResolve.high,
                                        )
                                    }}</span
                                >
                                <span v-else class="text-gray">-</span>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <medium
                                    v-c-tooltip="{
                                        content: 'Medium',
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                />
                                <span
                                    v-if="sla?.timeToResolve?.medium.value"
                                    class="text-gray text-nowrap"
                                    >{{
                                        getTimeDurationLabel(
                                            sla.timeToResolve.medium,
                                        )
                                    }}</span
                                >
                                <span v-else class="text-gray">-</span>
                            </div>
                            <div class="d-flex align-items-center gap-2">
                                <low
                                    v-c-tooltip="{
                                        content: 'Low',
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                />
                                <span
                                    v-if="sla?.timeToResolve?.low.value"
                                    class="text-gray text-nowrap"
                                    >{{
                                        getTimeDurationLabel(
                                            sla.timeToResolve.low,
                                        )
                                    }}</span
                                >
                                <span v-else class="text-gray">-</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!isTablet"
                    class="grid-cell grid-action-cell d-flex justify-content-end gap-1"
                >
                    <jet-button
                        v-c-tooltip="{
                            content: trans('general.action.edit'),
                        }"
                        class="btn-icon"
                        @click.stop="editSla(sla)"
                    >
                        <icon name="cil-pencil" />
                    </jet-button>
                    <jet-button
                        v-c-tooltip="{
                            content: trans('general.action.delete'),
                        }"
                        class="btn-icon btn-danger"
                        @click.stop="deleteSla(sla)"
                    >
                        <icon name="cil-trash" />
                    </jet-button>
                </div>
                <div v-else class="grid-cell" />
            </div>
        </div>
    </section>
</template>
<script setup>
import ExclamationMark from '@/../icons/ExclamationMark.vue';
import High from '@/../icons/High.vue';
import JetButton from '@/Jetstream/Button.vue';
import Low from '@/../icons/Low.vue';
import Medium from '@/../icons/Medium.vue';
import { isTablet } from '@/Utils/UseMedia';
import { DateTime } from 'luxon';
import { trans } from '@/mixins/i18n.js';
import { eventBus, events } from '@/eventBus.js';
import fullDate from '@/filters/fullDate';
import { router, usePage } from '@inertiajs/vue3';
import { route } from 'ziggy-js';

const props = defineProps({
    maintainer: Object,
    collapsed: Boolean,
});

const page = usePage();

const getTimeDurationLabel = (duration) => {
    let durationLabel = trans(`general.${duration.unit}`);
    return `${duration.value} ${durationLabel}`;
};

const getExpirationDateRange = (sla) => {
    if (!sla || (!sla.startDate && !sla.endDate)) {
        return '';
    }

    const startDate = DateTime.fromFormat(sla.startDate, 'y-MM-dd');
    const endDate = DateTime.fromFormat(sla.endDate, 'y-MM-dd');
    const locale = page.props.locale;

    return [fullDate(startDate, locale), fullDate(endDate, locale)]
        .map((el) => `<span class="text-nowrap">${el}</span>`)
        .join(' - ');
};

const getExpirationInfo = (sla) => {
    if (!sla || !sla.startDate || !sla.endDate) {
        return trans('maintainers.overview.sla_missing');
    }
    if (sla.expired) {
        return trans('maintainers.overview.sla_expired');
    }

    if (sla.expiresSoon) {
        return trans('maintainers.overview.sla_expires_soon');
    }
};

const editSla = (sla) => {
    eventBus.$emit(events.openCreateMaintainerServiceLevelAgreementModal, {
        maintainer: props.maintainer,
        sla,
    });
};

const deleteSla = (sla) => {
    router.delete(
        route('maintainers.slas.destroy', {
            maintainer: props.maintainer,
            sla: sla.id,
        }),
        {
            preserveScroll: true,
        },
    );
};

const shouldHighlightSla = (sla) => location.hash.includes(sla.id);
</script>
<style scoped>
.grid.collapsed {
    height: 0;
}
</style>
