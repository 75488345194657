<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h3 class="mb-0">{{ trans('owners.overview.title') }}</h3>
            <div class="d-flex align-items-end center gap-3">
                <small class="text-muted">{{
                    trans('owners.overview.results.count', {
                        total: owners.data.length,
                    })
                }}</small>
                <jet-button
                    v-if="hasAnyPermission('owner.create')"
                    color="primary"
                    @click.stop="createOwner"
                    >{{ trans('owners.overview.action.add') }}
                </jet-button>
            </div>
        </div>

        <card
            v-for="owner in owners.data"
            :key="owner.id"
            card-classes="mb-2"
            type="decent"
        >
            <header class="d-flex justify-content-between align-items-center">
                <h4 class="h6">{{ owner.name }}</h4>
                <div class="d-flex gap-1">
                    <jet-button
                        v-c-tooltip="{ content: trans('general.action.edit') }"
                        class="btn-icon"
                        @click="editOwner(owner)"
                    >
                        <icon name="cil-pencil" />
                    </jet-button>
                    <jet-button
                        v-c-tooltip="{
                            content: trans('general.action.delete'),
                        }"
                        class="btn-icon btn-danger"
                        @click="deleteOwner(owner)"
                    >
                        <icon name="cil-trash" />
                    </jet-button>
                </div>
            </header>
            <section>
                <row>
                    <column md="2">
                        <div>
                            {{
                                trans(
                                    'stakeholders.overview.assets_count.label',
                                    {
                                        count: owner.assetsCount || 0,
                                    },
                                )
                            }}
                        </div>
                        <div>
                            {{
                                trans(
                                    'stakeholders.overview.locations_count.label',
                                    {
                                        count:
                                            owner.topLevelLocationsCount || 0,
                                    },
                                )
                            }}
                        </div>
                    </column>
                    <column>
                        <div v-if="owner.email || owner.description">
                            <div v-if="owner.email">
                                <email-link
                                    :email="owner.email"
                                    :show-icon="true"
                                />
                            </div>
                            <div v-if="owner.description" class="d-flex gap-1">
                                <em style="width: 1rem" />
                                {{ owner.description }}
                            </div>
                        </div>
                        <div v-else>
                            {{ trans('general.not_available') }}
                        </div>
                    </column>
                </row>
            </section>
        </card>

        <update-or-create-owner-form-modal />
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import TotalTableItems from '@/Components/TotalTableItems.vue';
import EmailLink from '@/Components/EmailLink.vue';
import UpdateOrCreateOwnerFormModal from '@/Pages/Stakeholders/Owners/UpdateOrCreateOwnerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import DeleteStakeholderModal from '@/Pages/Stakeholders/DeleteStakeholderModal.vue';
import Card from '@/Components/Card.vue';

export default {
    name: 'OwnersOverview',
    components: {
        Card,
        DeleteStakeholderModal,
        UpdateOrCreateOwnerFormModal,
        EmailLink,
        TotalTableItems,
        Pagination,
        JetButton,
    },
    computed: {
        owners() {
            return this.$page.props.owners;
        },
    },
    methods: {
        createOwner() {
            eventBus.$emit(events.openCreateOwnerModal);
        },
        editOwner(item) {
            eventBus.$emit(events.openEditOwnerModal, { owner: item });
        },
        deleteOwner(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'owner',
            });
        },
    },
};
</script>
