import {
    cilAirplaneMode,
    cilArrowRight,
    cilBan,
    cilBarChart,
    cilBell,
    cilCalendar,
    cilCaretBottom,
    cilCaretTop,
    cilChartLine,
    cilCheck,
    cilCheckAlt,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronTop,
    cilClipboard,
    cilClock,
    cilCloudDownload,
    cilCloudUpload,
    cilCog,
    cilDataTransferDown,
    cilDescription,
    cilEnvelopeClosed,
    cilExternalLink,
    cilFile,
    cilFork,
    cilGroup,
    cilImagePlus,
    cilInfo,
    cilLayers,
    cilList,
    cilListFilter,
    cilLockLocked,
    cilLockUnlocked,
    cilLoop,
    cilMedicalCross,
    cilMenu,
    cilMobile,
    cilNotes,
    cilPaperPlane,
    cilPencil,
    cilPhone,
    cilPlus,
    cilQrCode,
    cilScreenDesktop,
    cilSitemap,
    cilTag,
    cilTags,
    cilTrash,
    cilUser,
    cilViewColumn,
    cilWarning,
    cilX,
    cilXCircle,
    flagSet,
} from '@coreui/icons';

import { logoCurie, logoLarge, logoSmall } from './logo';

export const iconsSet = Object.assign(
    {},
    { logoSmall, logoLarge, logoCurie },
    flagSet,
    {
        cilAirplaneMode,
        cilArrowRight,
        cilBan,
        cilBarChart,
        cilBell,
        cilCalendar,
        cilCaretBottom,
        cilCaretTop,
        cilChartLine,
        cilCheck,
        cilCheckAlt,
        cilCheckCircle,
        cilChevronBottom,
        cilChevronTop,
        cilClipboard,
        cilClock,
        cilCloudDownload,
        cilCloudUpload,
        cilCog,
        cilDataTransferDown,
        cilDescription,
        cilEnvelopeClosed,
        cilExternalLink,
        cilFile,
        cilFork,
        cilGroup,
        cilImagePlus,
        cilInfo,
        cilLayers,
        cilList,
        cilListFilter,
        cilLockLocked,
        cilLockUnlocked,
        cilLoop,
        cilMedicalCross,
        cilMenu,
        cilMobile,
        cilNotes,
        cilPaperPlane,
        cilPencil,
        cilPhone,
        cilPlus,
        cilQrCode,
        cilScreenDesktop,
        cilSitemap,
        cilTag,
        cilTags,
        cilTrash,
        cilUser,
        cilViewColumn,
        cilWarning,
        cilX,
        cilXCircle,
    },
);
