<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasFeature('performance-data') &&
            hasAnyPermission('performance_data.create')
        "
        :cancel-disabled="uploadInProgress"
        :is-processing="form.processing"
        :primary-button-label="trans('general.action.create')"
        :show="show"
        :submit-disabled="!form.isDirty || uploadInProgress"
        @close="closeModal"
        @submit="submitForm"
    >
        <template #title>Capture Performance Data</template>

        <div
            v-if="isLoading"
            class="d-flex align-items-center justify-content-center w-100"
        >
            <spinner size="md" />
        </div>
        <form v-else @submit.prevent="submitForm">
            <jet-select
                v-model="form.type"
                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                :invalidFeedback="form.errors.type"
                :options="types"
                :readonly="!!performanceDataId"
                :required="true"
                label="Performance Data"
            />
            <jet-input
                v-model="form.value"
                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7' }"
                :invalidFeedback="form.errors.value"
                :required="true"
                class="mb-5"
                label="Value"
            />

            <h5>Attachment</h5>
            <p>
                Upload files by dragging them onto the area below or click the
                "Browse" button to select them.
            </p>
            <file-upload
                v-model="form.attachments"
                @upload-started="uploadInProgress = true"
                @upload-completed="uploadInProgress = false"
            />
        </form>
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';
import { $http } from '@/bootstrap.js';

export default {
    name: 'CreatePerformanceDataModal',
    components: {
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            performanceDataId: null,
            uploadInProgress: false,
            isLoading: false,
            form: this.$inertia.form({
                type: null,
                value: null,
                attachments: [],
            }),
            types: [],
        };
    },
    mounted() {
        eventBus.$on(
            [
                events.openCreatePerformanceDataModal,
                events.editPerformanceDataItem,
            ],
            (data) => {
                this.form.reset();
                this.form.clearErrors();
                this.shouldRender = true;
                this.uploadInProgress = false;
                this.loadData(data);
                this.$nextTick(() => {
                    this.show = true;
                });
            },
        );
    },
    beforeUnmount() {
        eventBus.$off([
            events.openCreatePerformanceDataModal,
            events.editPerformanceDataItem,
        ]);
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
    methods: {
        loadData(data) {
            this.isLoading = true;
            this.performanceDataId = data?.id || null;

            let route = data?.id
                ? this.route('assets.performance.edit', {
                      asset: this.asset,
                      performance: data.id,
                  })
                : this.route('assets.performance.create', {
                      asset: this.asset,
                  });

            $http
                .get(route)
                .then((response) => {
                    this.types = response.data.types;
                    this.form.type =
                        response.data.performanceData?.type.value || null;
                    this.form.value =
                        response.data.performanceData?.value.toString() || null;
                    this.form.attachments = [];
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        submitForm() {
            let route = this.performanceDataId
                ? this.route('assets.performance.update', {
                      asset: this.asset,
                      performance: this.performanceDataId,
                  })
                : this.route('assets.performance.store', {
                      asset: this.asset,
                  });

            this.form
                .transform((data) => {
                    if (this.performanceDataId) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    onSuccess: () => {
                        this.closeModal();
                    },
                });
        },
        closeModal() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
    },
};
</script>
