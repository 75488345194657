import emitter from 'tiny-emitter/instance';

export const eventBus = {
    $on: function (...args) {
        const events = Array.isArray(args[0]) ? args[0] : [args[0]];
        events.forEach((event) => emitter.on(event, ...args.slice(1)));
    },
    $once: (...args) => emitter.once(...args),
    $off: function (...args) {
        const events = Array.isArray(args[0]) ? args[0] : [args[0]];
        events.forEach((event) => emitter.off(event, ...args.slice(1)));
    },
    $emit: function (...args) {
        let lastArg = args[args.length - 1];
        let timeout =
            typeof lastArg === 'object' && lastArg.hasOwnProperty('timeout')
                ? lastArg.timeout
                : 0;

        setTimeout(() => {
            emitter.emit(...args);
        }, timeout);
    },
};

setInterval(() => {
    const isActiveTab = (document.hasFocus && document.hasFocus()) ?? false;

    if (isActiveTab) {
        eventBus.$emit(
            events.clockUpdated,
            Math.floor(new Date().getTime() / 1000),
        );
    }
}, 1000);

export const events = {
    presentFlashMessage: 'present.flash.message',
    updateNotifications: 'notifications.update',
    assetOperabilityUpdated: 'asset.operability.updated', // { asset: String, operability: String, reason: String }
    clockUpdated: 'clock.updated',
    openCreatePerformanceDataModal: 'open.create.performance.data.modal',
    editPerformanceDataItem: 'edit.performance.data.item',
    openTestRecordsModal: 'open.test.records.modal',
    openCreateStatusCheckModal: 'open.create.status.check.modal',
    openEditOwnerModal: 'open.edit.owner.modal',
    openCreateOwnerModal: 'open.create.owner.modal',
    openEditOperatorModal: 'open.edit.operator.modal',
    openCreateOperatorModal: 'open.create.operator.modal',
    openEditMaintainerModal: 'open.edit.maintainer.modal',
    openCreateMaintainerModal: 'open.create.maintainer.modal',
    openCreateWorkOrderModal: 'open.create.work.order.modal',
    openConfirmDeleteStakeholderModal: 'open.confirm.delete.stakeholder.modal',
    openQrCodeScanner: 'open.qr.code.scanner', // { assetId: String, type: String, onSuccessfulScan: () => void }
    openWorkOrderDetailsModal: 'open.work.order.details.modal',
    workOrderUpdated: 'workorder.updated',
    workOrderStatusUpdated: 'workorder.status.updated', // { workOrderId: String, status: String }
    openUpdateCadenceConfigModal: 'open.update.cadence.config.modal',
    openCreateSpecificLocationModal: 'open.create.specific.location.modal',
    openDeleteWorkOrderModal: 'open.delete.work.order.modal',
    openConfirmAssetStatusChangeModal: 'open.confirm.asset.status.change.modal',
    openConfirmWorkOrderCreation: 'open.confirm.workorder.creation',
    openDeleteLocationModal: 'open.delete.location.modal',
    openConfirmDiscardChangesModal: 'open.confirm.discard.changes.modal',
    openUnsavedChangesModal: 'open.unsaved.changes.modal',
    openCreateMaintainerServiceLevelAgreementModal:
        'open.create.maintainer.service.level.agreement.modal',
};
