<template>
    <div class="d-flex flex-column flex-grow-1">
        <h3 class="mb-3">{{ trans('users.overview.title') }}</h3>
        <card card-classes="mb-3 d-flex flex-column flex-grow-1" type="decent">
            <grouped-users :groups="userGroups" @show-all="showAllUsers" />
            <div v-if="totalUserCount > 10" class="d-flex justify-content-end">
                <jet-button
                    :is-processing="isLoadingUsers"
                    class="btn-text"
                    @click.stop="showAllUsers"
                    >{{ trans('general.action.show_all') }} ({{
                        totalUserCount
                    }})
                </jet-button>
            </div>
        </card>
        <dialog-modal
            :close-button="true"
            :show="showAllUsersModal"
            @close="showAllUsersModal = false"
        >
            <template #title>{{ trans('locations.users.label') }}</template>

            <grouped-users :groups="allLocationUsers" />

            <template #footer>
                <div />
            </template>
        </dialog-modal>
    </div>
</template>
<script setup>
import Card from '@/Components/Card.vue';
import { router, usePage } from '@inertiajs/vue3';
import { computed, ref } from 'vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import GroupedUsers from '@/Pages/Locations/GroupedUsers.vue';
import JetButton from '@/Jetstream/Button.vue';

const page = usePage();
const showAllUsersModal = ref(false);
const isLoadingUsers = ref(false);

const userGroups = computed(() => page.props.userGroups);
const allLocationUsers = computed(() => page.props.allLocationUsers);
const totalUserCount = computed(() =>
    (userGroups.value || []).reduce((a, b) => a + b.total, 0),
);

const showAllUsers = () => {
    if (isLoadingUsers.value) {
        return;
    }

    isLoadingUsers.value = true;

    router.get(
        route('locations.edit', page.props.location.id),
        {},
        {
            preserveState: true,
            only: ['allLocationUsers'],
            onSuccess: () => {
                showAllUsersModal.value = true;
            },
            onFinish: () => {
                isLoadingUsers.value = false;
            },
        },
    );
};
</script>
