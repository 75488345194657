<template>
    <table class="table-unstyled">
        <tbody>
            <tr v-for="(group, index) in groupedDays">
                <td>
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <icon v-if="index === 0" name="cil-clock" />
                    </div>
                </td>
                <td style="width: 4px" />
                <td>{{ group.label }}</td>
                <td style="width: 20px; height: 20px" />
                <td>{{ group.schedule }}</td>
            </tr>
            <tr v-if="!workingHours?.length">
                <td>
                    <div
                        class="d-flex align-items-center justify-content-center"
                    >
                        <icon name="cil-clock" />
                    </div>
                </td>
                <td style="width: 4px" />
                <td>24/7</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { computed } from 'vue';
import { getWeekday } from '@/Pages/Stakeholders/Maintainers/weekdays.js';
import { usePage } from '@inertiajs/vue3';

const props = defineProps({ workingHours: [Array, String, null] });
const page = usePage();

function groupDaysBySchedule(schedule) {
    if (!schedule || schedule.length === 0 || schedule === '24/7') return [];

    const grouped = new Map();

    const dayOrder = [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday',
    ];

    const currentLocale = computed(() => page.props.locale);
    const localizeDay = (day) => getWeekday(day, currentLocale)?.label;

    // Group days by "from" and "to" times
    schedule.forEach(({ day, from, to }) => {
        const key = `${from}-${to}`;
        if (!grouped.has(key)) {
            grouped.set(key, new Set());
        }
        grouped.get(key).add(day);
    });

    // Helper function to check if days are consecutive
    const isConsecutive = (days) => {
        if (days.length === 1) return false;

        const indexes = Array.from(days)
            .map((day) => dayOrder.indexOf(day))
            .sort((a, b) => a - b);
        for (let i = 0; i < indexes.length - 1; i++) {
            if (indexes[i + 1] !== indexes[i] + 1) {
                return false;
            }
        }
        return true;
    };

    // Transform groups into statements
    return Array.from(grouped.entries()).map(([key, days]) => {
        const [from, to] = key.split('-');
        const sortedDays = Array.from(days).sort((a, b) => {
            return dayOrder.indexOf(a) - dayOrder.indexOf(b);
        });

        let dayStatement;
        if (isConsecutive(sortedDays)) {
            dayStatement = `${localizeDay(sortedDays[0])} - ${localizeDay(sortedDays[sortedDays.length - 1])}`;
        } else {
            dayStatement = sortedDays.map(localizeDay).join(', ');
        }

        return {
            label: dayStatement,
            schedule: `${from} - ${to}`,
        };
    });
}

const groupedDays = computed(() => {
    return groupDaysBySchedule(props.workingHours);
});
</script>
