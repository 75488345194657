<template>
    <dialog-modal
        v-if="shouldRender && hasAnyPermission('location.delete')"
        :is-processing="isDeleting"
        :primary-button-label="trans('general.action.yes_delete')"
        :show="showModal"
        type="danger"
        @close="closeModal"
        @submit="deleteLocation"
    >
        <template #title>{{
            trans(`locations.delete.${type}.title`)
        }}</template>
        {{ trans(`locations.delete.${type}.description`) }}
    </dialog-modal>
</template>
<script setup>
import DialogModal from '@/Jetstream/DialogModal.vue';
import { router } from '@inertiajs/vue3';
import { eventBus, events } from '@/eventBus.js';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

const emit = defineEmits(['close', 'deleted']);
const shouldRender = ref(false);
const showModal = ref(false);
const locationId = ref(null);
const isDeleting = ref(false);
const type = ref('airport');
const locationName = ref(null);

const deleteLocation = () => {
    if (!locationId.value || isDeleting.value) {
        return;
    }

    isDeleting.value = true;

    router.delete(route('locations.destroy', locationId.value), {
        preserveScroll: true,
        only: ['location', 'locationWithChildren', 'flash'],
        onSuccess: () => {
            emit('deleted', locationId.value);
            closeModal();
        },
        onFinish: () => {
            isDeleting.value = false;
            closeModal();
        },
    });
};

const closeModal = () => {
    showModal.value = false;

    setTimeout(() => {
        shouldRender.value = false;
        locationId.value = null;
        locationName.value = null;
    }, 150);

    emit('close');
};

onMounted(() => {
    eventBus.$on([events.openDeleteLocationModal], (data) => {
        shouldRender.value = true;
        locationId.value = data?.locationId;
        type.value = data?.type || 'airport';
        locationName.value = data?.name || null;

        nextTick(() => {
            showModal.value = true;
        });
    });
});

onBeforeUnmount(() => {
    eventBus.$off([events.openDeleteLocationModal]);
});
</script>
