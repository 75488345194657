<template>
    <app-header>
        <div class="d-flex gap-3 align-items-center">
            <toggler
                class="d-lg-none p-2 d-inline-flex justify-content-center"
                style="width: 44px; height: 44px"
                @click="sidebar.toggleVisible()"
            />
            <icon
                class="d-md-block d-none"
                icon="logoLarge"
                style="width: 80px; height: 28px"
            />
        </div>
        <header-nav>
            <nav-item>
                <notification-center />
            </nav-item>
            <nav-item>
                <the-header-dropdown-account />
            </nav-item>
            <nav-item>
                <button
                    class="btn btn-success d-inline-flex scan-btn btn-extended"
                    style="gap: 5px; min-width: auto; min-height: 2rem"
                    type="button"
                    @click.prevent="openQrScanner"
                >
                    <icon
                        name="cil-qr-code"
                        style="shape-rendering: crispEdges"
                    />
                    {{ trans('general.action.scan') }}
                </button>
            </nav-item>
        </header-nav>
    </app-header>
</template>

<script setup>
import NotificationCenter from '@/Components/NotificationCenter/NotificationCenter.vue';
import HeaderNav from '@/Components/HeaderNav.vue';
import NavItem from '@/Components/NavItem.vue';
import TheHeaderDropdownAccount from '@/Containers/HeaderDropdownAccount.vue';
import AppHeader from '@/Components/AppHeader.vue';
import { useSidebarStore } from '@/stores/sidebar.js';
import Toggler from '@/Components/Toggler.vue';
import { eventBus, events } from '@/eventBus.js';

const sidebar = useSidebarStore();
const openQrScanner = () => {
    eventBus.$emit(events.openQrCodeScanner);
};
</script>
<style scoped>
.scan-btn:hover,
.scan-btn:focus {
    background: var(--c-btn-bg) !important;
}

:deep(.dropdown-toggle) {
    padding: 0 5px !important;
}
</style>
