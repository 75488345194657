<template>
    <app-layout>
        <page-header :title="trans('assets.details.create_new_asset')" />
        <row>
            <column>
                <jet-form-section type="decent" @submitted="handleSubmit">
                    <template #form>
                        <asset-information-form-section :form="form">
                            <template #title>
                                <h3 class="h4">
                                    {{ trans('assets.details.header') }}
                                </h3>
                                <p>
                                    {{
                                        trans(
                                            'assets.details.asset_identification.description',
                                        )
                                    }}
                                </p>
                            </template>
                        </asset-information-form-section>
                        <stakeholder-form-section :form="form" class="mt-4">
                            <template #title>
                                <h3 class="h4">
                                    {{ trans('stakeholders.overview.title') }}
                                </h3>
                            </template>
                        </stakeholder-form-section>
                        <quality-assurance-configuration-section
                            :form="form"
                            class="mt-4"
                        >
                            <template #title>
                                <h3 class="h4">
                                    {{
                                        trans(
                                            'assets.details.qa_configuration.header',
                                        )
                                    }}
                                </h3>
                                <p>
                                    {{
                                        trans(
                                            'assets.details.qa_configuration.description',
                                        )
                                    }}
                                </p>
                            </template>
                        </quality-assurance-configuration-section>
                        <section class="mt-4">
                            <h3 class="h4">
                                {{ trans('attachments.overview.header') }}
                            </h3>
                            <attachments
                                :can-create="
                                    hasAnyPermission([
                                        'asset.attachment.create',
                                    ])
                                "
                                :can-delete="
                                    hasAnyPermission([
                                        'asset.attachment.delete',
                                    ])
                                "
                                :can-edit="
                                    hasAnyPermission([
                                        'asset.attachment.update',
                                    ])
                                "
                                :can-read="
                                    hasAnyPermission(['asset.attachment.index'])
                                "
                                :form="form"
                                :show-empty-state="
                                    !hasAnyPermission([
                                        'asset.attachment.create',
                                    ])
                                "
                            />
                        </section>
                    </template>

                    <template #actions>
                        <jet-form-buttons
                            :cancel-route="cancelRoute"
                            :disabled="!form.isDirty"
                            :has-changes="form.isDirty"
                            :is-processing="form.processing"
                            :is-update-form="true"
                            @submit="handleSubmit"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetSelect from '@/Jetstream/Select.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import SimpleQrCodeScannerModal from '@/Components/SimpleQrCodeScannerModal.vue';
import DropdownWithAction from '@/Components/DropdownWithAction.vue';
import DesignationDropdown from '@/Pages/Assets/Edit/DesignationDropdown.vue';
import ManufacturerDropdown from '@/Pages/Assets/Edit/ManufacturerDropdown.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import { $http } from '@/bootstrap.js';
import LocationFormFields from '@/Pages/Assets/Edit/LocationFormFields.vue';
import PageHeader from '@/Components/PageHeader.vue';
import AssetInformationFormSection from '@/Pages/Assets/Edit/AssetInformationFormSection.vue';
import StakeholderFormSection from '@/Pages/Stakeholders/Components/StakeholderFormSection.vue';
import QualityAssuranceConfigurationSection from '@/Pages/Assets/Edit/QualityAssuranceConfigurationSection.vue';
import Attachments from '@/Components/Attachments.vue';
import { useUnsavedChangesWarning } from '@/Utils/UseUnsavedChangesWarning';
import { defaultSla } from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerSlaFormModal.vue';

export default {
    components: {
        Attachments,
        QualityAssuranceConfigurationSection,
        StakeholderFormSection,
        AssetInformationFormSection,
        PageHeader,
        LocationFormFields,
        ButtonAddNew,
        ManufacturerDropdown,
        AppLayout,
        DesignationDropdown,
        DropdownWithAction,
        JetButton,
        JetFormButtons,
        JetInput,
        JetFormSection,
        JetSelect,
        JetDatepicker,
        SimpleQrCodeScannerModal,
    },

    data() {
        return {
            models: [],
            isLoadingModels: false,
            form: this.$inertia.form({
                petitecIdentifier: '',
                manufacturer: '',
                serialNumber: '',
                customerTrackingNumber: '',
                locationId: '',
                modelId: '',
                screeningApplication: '',
                purchasedAt: '',
                deployedAt: '',
                critical: 'no',
                warrantyStartDate: '',
                warrantyEndDate: '',
                owners: [],
                operators: [],
                maintainers: [],
                serviceLevelAgreement: Object.assign({}, defaultSla),
                statusCheckCadence: {
                    unit: 'never',
                    value: null,
                    startDate: null,
                },
                testCadence: {
                    unit: 'never',
                    value: null,
                    startDate: null,
                },
                maintenanceCadence: {
                    unit: 'never',
                    value: null,
                    startDate: null,
                },
                attachments: [],
            }),
        };
    },

    mounted() {
        useUnsavedChangesWarning({
            hasChanges: () => this.form.isDirty || this.form.processing,
            handleSubmit: this.handleSubmit,
        });
    },

    computed: {
        cancelRoute() {
            return this.route('assets.index');
        },
        asset() {
            return this.$page.props.asset;
        },

        noModelsAvailable() {
            if (this.models && this.models.length === 0) {
                return this.trans('designations.not_available');
            }

            return undefined;
        },
        isAllowedToCreateModels() {
            return this.hasAnyPermission('model.create');
        },
        fileUploadUrl() {
            return this.route('attachments.store');
        },
    },

    methods: {
        handleSubmit() {
            return new Promise((resolve) => {
                this.form
                    .transform(this.transformData)
                    .post(this.route('assets.store'), {
                        onSuccess: () => resolve(true),
                        onError: () => resolve(false),
                    });
            });
        },
        transformStartDateIfPresent(data, key) {
            if (data[key]?.startDate) {
                data[key].startDate = this.formatAsApiDate(data[key].startDate);
            }
        },
        transformData(data) {
            data = Object.assign({}, data);

            this.transformStartDateIfPresent(data, 'statusCheckCadence');
            this.transformStartDateIfPresent(data, 'testCadence');
            this.transformStartDateIfPresent(data, 'maintenanceCadence');

            return {
                ...data,
                deployedAt: this.formatAsApiDate(data.deployedAt),
                purchasedAt: this.formatAsApiDate(data.purchasedAt),
                warrantyStartDate: this.formatAsApiDate(data.warrantyStartDate),
                warrantyEndDate: this.formatAsApiDate(data.warrantyEndDate),
                critical: data.critical === 'yes',
            };
        },
        loadModels({ selectedModelId } = {}) {
            this.models = [];
            this.form.modelId = null;

            if (!this.form.manufacturer) {
                return;
            }

            this.form.processing = true;
            this.isLoadingModels = true;

            $http
                .get(
                    this.route('models.index', {
                        manufacturer: this.form.manufacturer,
                    }),
                )
                .then((resp) => {
                    this.models = this.transformModels(resp.data.data);

                    if (this.models.length === 1) {
                        this.form.modelId = this.models[0].value;
                    } else {
                        this.form.modelId = selectedModelId;
                    }
                })
                .finally(() => {
                    this.form.processing = false;
                    this.isLoadingModels = false;
                });
        },
        handleQrCodeSuccess(code) {
            this.form.petitecIdentifier = code.uuid;

            setTimeout(() => {
                this.isQrCodeModalVisible = false;
            }, 300);
        },
        addNewModel() {
            if (!!this.selectedManufacturerId) {
                this.isCreateModelModalVisible = true;
            }
        },
        handleCreateModelSuccess(data) {
            this.loadModels({ selectedModelId: data.id });

            this.isCreateModelModalVisible = false;
        },
        transformModels(models) {
            return models.map((model) => ({
                value: model.id,
                label: model.designation,
                type: model.type,
            }));
        },
    },
};
</script>
