<template>
    <span
        class="d-inline-flex align-items-center gap-1"
        style="line-height: 22px"
    >
        <icon
            v-if="showIcon"
            class="text-primary flex-shrink-0"
            name="cil-envelope-closed"
        />
        <a :href="link" target="_blank">
            <small v-if="size === 'sm'">{{ email }}</small>
            <span v-else>{{ email }}</span>
        </a>
    </span>
</template>
<script>
export default {
    name: 'EmailLink',
    props: {
        email: String,
        showIcon: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
        },
    },
    computed: {
        link() {
            return 'mailto:' + this.email;
        },
    },
};
</script>
