<template>
    <dialog-modal
        v-if="shouldRender && hasAnyPermission(['maintainer.update'])"
        :closeable="false"
        :is-processing="form.processing"
        :primary-button-label="
            !sla ? trans('general.action.add') : trans('general.action.save')
        "
        :show="show"
        :submit-disabled="!form.isDirty"
        @close="close"
        @submit="submitForm"
    >
        <template #title>{{ title }}</template>
        <form :key="componentKey" class="mb-3" @submit.prevent="submitForm">
            <service-level-agreement-form
                v-model="form"
                :asset-types="assetTypes"
                :attachments="attachments"
                :contracting-authorities="contractingAuthorities"
                @updated-attachment="onAttachmentUpdated"
                @deleted-attachment="onAttachmentDeleted"
            />
        </form>

        <required-fields />
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';
import ServiceLevelAgreementForm from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementForm.vue';
import { $http } from '@/bootstrap.js';
import RequiredFields from '@/Components/RequiredFields.vue';
import WorkingHoursSelector from '@/Pages/Stakeholders/Maintainers/WorkingHoursSelector.vue';

export const defaultSla = {
    timeToRespond: {
        high: {
            unit: 'hours',
            value: null,
        },
        medium: {
            unit: 'hours',
            value: null,
        },
        low: {
            unit: 'hours',
            value: null,
        },
    },
    timeToResolve: {
        high: {
            unit: 'hours',
            value: null,
        },
        medium: {
            unit: 'hours',
            value: null,
        },
        low: {
            unit: 'hours',
            value: null,
        },
    },
};

export default {
    name: 'UpdateOrCreateMaintainerSlaFormModal',
    emits: ['created', 'updated'],
    components: {
        WorkingHoursSelector,
        RequiredFields,
        ServiceLevelAgreementForm,
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            maintainer: null,
            sla: null,
            componentKey: 0,
            assetTypes: [],
            contractingAuthorities: [],
            attachments: [],
            form: this.$inertia.form(
                Object.assign({}, defaultSla, {
                    type: null,
                    contractingAuthorityId: null,
                    attachments: null,
                    startDate: null,
                    endDate: null,
                }),
            ),
        };
    },
    mounted() {
        eventBus.$on(
            [events.openCreateMaintainerServiceLevelAgreementModal],
            (data) => {
                this.maintainer = data?.maintainer || null;
                this.sla = data?.sla || null;

                this.form.reset();
                this.loadMaintainerInfo();
                this.showModal();
            },
        );
    },
    beforeUnmount() {
        eventBus.$off([events.openCreateMaintainerServiceLevelAgreementModal]);
    },
    computed: {
        title() {
            return !!this.sla
                ? this.trans('maintainers.sla.edit.title')
                : this.trans('maintainers.sla.create.title');
        },
    },
    methods: {
        loadMaintainerInfo() {
            const route = this.sla
                ? this.route('maintainers.slas.edit', {
                      maintainer: this.maintainer.id,
                      sla: this.sla.id,
                  })
                : this.route('maintainers.slas.create', this.maintainer);

            $http.get(route).then((response) => {
                const data = response.data;
                this.assetTypes = data.assetTypes;
                this.contractingAuthorities = (
                    data.contractingAuthorities || []
                ).map((el) => ({
                    label: el.name,
                    value: el.id,
                }));

                const sla = data.serviceLevelAgreement;

                this.form = this.$inertia.form(
                    Object.assign({}, defaultSla, {
                        type: sla?.type.value,
                        contractingAuthorityId: sla?.contractingAuthority?.id,
                        timeToResolve:
                            sla?.timeToResolve || defaultSla.timeToResolve,
                        timeToRespond:
                            sla?.timeToRespond || defaultSla.timeToRespond,
                        startDate: sla?.startDate,
                        endDate: sla?.endDate,
                    }),
                );

                this.attachments = sla?.attachments || [];

                this.componentKey += 1;
            });
        },
        submitForm() {
            let route = !!this.sla
                ? this.route('maintainers.slas.update', {
                      maintainer: this.maintainer,
                      sla: this.sla,
                  })
                : this.route('maintainers.slas.store', {
                      maintainer: this.maintainer,
                  });

            this.form
                .transform((data) => {
                    if (!!this.sla) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.close();
                    },
                });
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },

        showModal() {
            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        },

        onAttachmentUpdated(attachment) {
            this.attachments = this.attachments.map((el) => {
                if (el.id === attachment.id) {
                    return attachment;
                }
                return el;
            });
        },

        onAttachmentDeleted(attachment) {
            this.attachments = this.attachments.filter(
                (el) => el.id !== attachment.id,
            );
        },
    },
};
</script>
