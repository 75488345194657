<template>
    <span class="d-flex align-items-center gap-1">
        <icon v-if="showIcon" class="text-primary" name="cil-envelope-closed" />
        <a :href="link" target="_blank">
            <small v-if="size === 'sm'">{{ email }}</small>
            <span v-else>{{ email }}</span>
        </a>
    </span>
</template>
<script>
export default {
    name: 'EmailLink',
    props: {
        email: String,
        showIcon: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
        },
    },
    computed: {
        link() {
            return 'mailto:' + this.email;
        },
    },
};
</script>
