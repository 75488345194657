<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-2">
            <h3 class="mb-0">{{ trans('maintainers.overview.title') }}</h3>
            <div class="d-flex align-items-end center gap-3">
                <small class="text-muted">{{
                    trans('maintainers.overview.results.count', {
                        total: maintainers?.data.length,
                    })
                }}</small>
                <jet-button
                    v-if="hasAnyPermission('maintainer.create')"
                    color="primary"
                    @click.stop="createMaintainer"
                    >{{ trans('maintainers.overview.action.add') }}
                </jet-button>
            </div>
        </div>

        <deferred data="maintainers">
            <template #fallback>
                <spinner />
            </template>
            <card
                v-for="maintainer in maintainers.data"
                :key="maintainer.id"
                card-classes="mb-2"
                type="decent"
            >
                <header
                    :style="{
                        top: isDesktop ? '128px' : '55px',
                        margin: isDesktop ? '-21px' : '-15px',
                    }"
                    class="d-flex justify-content-between align-items-center mb-3 position-sticky bg-white"
                    style="
                        padding: 8px 20px 8px;
                        z-index: 1;
                        border-top-right-radius: 6px;
                        border-top-left-radius: 6px;
                    "
                >
                    <h4
                        class="h6 d-flex align-items-center gap-2"
                        @click.stop="toggleMaintainer(maintainer)"
                    >
                        <arrow-down
                            v-if="
                                isMobile &&
                                maintainer.serviceLevelAgreements.length
                            "
                            :class="{
                                collapsed: isMaintainerCollapsed(maintainer),
                            }"
                            class="text-primary collapse-toggle d-sm-none"
                        />
                        {{ maintainer.name }}
                    </h4>
                    <div class="d-flex gap-1">
                        <jet-button
                            style="min-width: 0; height: 30px"
                            @click.stop="createSla(maintainer)"
                            >{{ trans('maintainers.overview.action.add_sla') }}
                        </jet-button>
                        <jet-button
                            v-c-tooltip="{
                                content: trans('general.action.edit'),
                            }"
                            class="btn-icon"
                            @click="editMaintainer(maintainer)"
                        >
                            <icon name="cil-pencil" />
                        </jet-button>
                        <jet-button
                            v-c-tooltip="{
                                content: trans('general.action.delete'),
                            }"
                            class="btn-icon btn-danger"
                            @click="deleteMaintainer(maintainer)"
                        >
                            <icon name="cil-trash" />
                        </jet-button>
                    </div>
                </header>
                <maintainer-general-info :maintainer="maintainer" />
                <service-level-agreements-list
                    :collapsed="isMaintainerCollapsed(maintainer)"
                    :maintainer="maintainer"
                />
            </card>
        </deferred>

        <update-or-create-maintainer-form-modal />
        <update-or-create-maintainer-sla-form-modal />
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import UpdateOrCreateMaintainerFormModal from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerFormModal.vue';
import { eventBus, events } from '@/eventBus.js';
import Card from '@/Components/Card.vue';
import { isDesktop, isMobile } from '@/Utils/UseMedia';
import ArrowDown from '@/../icons/ArrowDown.vue';
import ServiceLevelAgreementsList from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementsList.vue';
import MaintainerGeneralInfo from '@/Pages/Stakeholders/Maintainers/MaintainerGeneralInfo.vue';
import UpdateOrCreateMaintainerSlaFormModal from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerSlaFormModal.vue';
import { Deferred } from '@inertiajs/vue3';
import Spinner from '@/Components/Spinner.vue';

export default {
    name: 'MaintainersOverview',
    components: {
        Spinner,
        Deferred,
        UpdateOrCreateMaintainerSlaFormModal,
        MaintainerGeneralInfo,
        ServiceLevelAgreementsList,
        ArrowDown,
        Card,
        UpdateOrCreateMaintainerFormModal,
        JetButton,
    },
    setup() {
        return { isDesktop, isMobile };
    },
    data() {
        return {
            expandedMaintainer: null,
        };
    },
    computed: {
        maintainers() {
            return this.$page.props.maintainers;
        },
    },
    methods: {
        createMaintainer() {
            eventBus.$emit(events.openCreateMaintainerModal);
        },
        editMaintainer(item) {
            eventBus.$emit(events.openEditMaintainerModal, {
                maintainer: item,
            });
        },
        deleteMaintainer(item) {
            eventBus.$emit(events.openConfirmDeleteStakeholderModal, {
                stakeholder: item,
                type: 'maintainer',
            });
        },
        createSla(maintainer) {
            eventBus.$emit(
                events.openCreateMaintainerServiceLevelAgreementModal,
                {
                    maintainer: maintainer,
                },
            );
        },
        deleteSla(item) {},
        toggleMaintainer(maintainer) {
            if (this.expandedMaintainer === maintainer.id) {
                this.expandedMaintainer = null;
            } else {
                this.expandedMaintainer = maintainer.id;
            }
        },
        isMaintainerCollapsed(maintainer) {
            if (this.isDesktop) {
                return false;
            }

            return this.expandedMaintainer !== maintainer.id;
        },
    },
};
</script>
<style scoped>
.grid.collapsed {
    height: 0;
}
</style>
