<template>
    <app-layout>
        <div>
            <page-header :title="trans('stakeholders.edit.header.title')" />
            <row>
                <column>
                    <jet-form-section type="decent" @submitted="handleSubmit">
                        <template #form>
                            <stakeholder-form-section :form="form" />
                        </template>
                        <template #actions>
                            <jet-form-buttons
                                :cancel-route="$route('assets.show', asset)"
                                :disabled="!form.isDirty || form.processing"
                                :has-changes="form.isDirty"
                                :is-processing="form.processing"
                                :is-update-form="true"
                                @submit="handleSubmit"
                            />
                        </template>
                    </jet-form-section>
                </column>
            </row>
            <update-or-create-owner-form-modal
                v-if="hasAnyPermission('owner.create')"
                @created="refreshData('owners')"
            />
            <update-or-create-operator-form-modal
                v-if="hasAnyPermission('operator.create')"
                @created="refreshData('operators')"
            />
            <update-or-create-maintainer-form-modal
                v-if="hasAnyPermission('maintainer.create')"
                @created="refreshData('maintainers')"
            />
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import { eventBus, events } from '@/eventBus.js';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import UpdateOrCreateMaintainerFormModal from '@/Pages/Stakeholders/Maintainers/UpdateOrCreateMaintainerFormModal.vue';
import UpdateOrCreateOperatorFormModal from '@/Pages/Stakeholders/Operators/UpdateOrCreateOperatorFormModal.vue';
import UpdateOrCreateOwnerFormModal from '@/Pages/Stakeholders/Owners/UpdateOrCreateOwnerFormModal.vue';
import ButtonAddNew from '@/Components/ButtonAddNew.vue';
import PageHeader from '@/Components/PageHeader.vue';
import StakeholderFormSection from '@/Pages/Stakeholders/Components/StakeholderFormSection.vue';

export default {
    name: 'StakeholdersEdit',
    components: {
        StakeholderFormSection,
        PageHeader,
        ButtonAddNew,
        UpdateOrCreateOwnerFormModal,
        UpdateOrCreateOperatorFormModal,
        JetFormButtons,
        MultiSelectDropdown,
        JetFormSection,
        AppLayout,
        UpdateOrCreateMaintainerFormModal,
    },
    data() {
        return {
            form: this.$inertia.form({
                owners: [],
                operators: [],
                maintainers: [],
            }),
        };
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
    mounted() {
        this.form = this.$inertia.form({
            owners: this.asset.owners?.map((owner) => owner.id) || [],
            operators:
                this.asset.operators?.map((operator) => operator.id) || [],
            maintainers:
                this.asset.maintainers?.map((maintainer) => maintainer.id) ||
                [],
        });
    },
    methods: {
        handleSubmit() {
            this.form.put(route('assets.stakeholders.update', this.asset));
        },
        addNewMaintainer(hideElement) {
            hideElement();
            eventBus.$emit(events.openCreateMaintainerModal);
        },
        addNewOperator(hideElement) {
            hideElement();
            eventBus.$emit(events.openCreateOperatorModal);
        },
        addNewOwner(hideElement) {
            hideElement();
            eventBus.$emit(events.openCreateOwnerModal);
        },
        refreshData(type) {
            this.$inertia.reload({ only: [type] });
        },
    },
};
</script>
