<template>
    <app-layout>
        <page-header title="Inoperable Assets">
            <div
                class="d-sm-flex justify-content-between align-items-start mt-3"
            >
                <reporting-filter-bar
                    key="filter-bar-1"
                    :query-params="activeQueryParams"
                />
                <jet-button
                    :disabled="true"
                    class="d-none d-sm-flex"
                    color="secondary"
                    >{{ trans('general.action.export') }}
                </jet-button>
            </div>
        </page-header>
        <row>
            <column>
                <card>
                    <template #header>
                        <b>Inoperable Equipment Report</b>
                    </template>
                    <c-alert v-if="missingLocations.length" color="info">
                        <div>
                            The report(s) for the following location(s) are not
                            available yet:
                        </div>
                        <ul class="list-unstyled">
                            <li v-for="location in missingLocations">
                                <div
                                    class="d-flex align-items-center"
                                    style="gap: 10px"
                                >
                                    <icon icon="cil-clock" />
                                    {{ location }}
                                </div>
                            </li>
                        </ul>
                    </c-alert>
                    <div
                        class="d-flex justify-content-between align-items-start pb-2"
                    >
                        <report-meta-info
                            :report="report"
                            :total-assets-count="totalAssetsCount"
                        />
                    </div>
                    <daily-light-report-table
                        v-if="report?.type === 'daily'"
                        :query-params="activeQueryParams"
                    />
                    <weekly-light-report-table v-else />
                </card>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import ReportingFilterBar from '@/Pages/ReportingLight/ReportingFilterBar.vue';
import DailyLightReportTable from '@/Pages/ReportingLight/DailyLightReportTable.vue';
import WeeklyLightReportTable from '@/Pages/ReportingLight/WeeklyLightReportTable.vue';
import ReportMetaInfo from '@/Pages/ReportingLight/ReportMetaInfo.vue';

import Card from '@/Components/Card.vue';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { DateTime } from 'luxon';

export default {
    name: 'ReportingLight',
    components: {
        PageHeader,
        PageTitle,
        Card,
        ReportMetaInfo,
        WeeklyLightReportTable,
        DailyLightReportTable,
        ReportingFilterBar,
        JetButton,
        AppLayout,
    },
    setup() {
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                type: 'daily',
                search: null,
                locations: [],
                specificLocations: [],
                date: DateTime.now().toFormat('yyyy-MM-dd'),
                sort: { column: 'status', asc: false },
            },
            route: route('reporting-light.index'),
            options: {
                only: ['totalAssetsCount', 'availableReportDates', 'report'],
            },
        });

        return {
            activeQueryParams,
        };
    },
    computed: {
        totalAssetsCount() {
            return this.$page.props.totalAssetsCount || 0;
        },
        report() {
            return this.$page.props.report;
        },
        missingLocations() {
            return [];
        },
        availableLocationIds() {
            return this.$page.props.reportAvailableForLocationIds;
        },
    },
};
</script>
