<template>
    <small
        class="d-flex text-muted text-truncate"
        style="line-height: 14px; gap: 4px"
    >
        <icon class="flex-shrink-0" icon="cil-clock" size="sm" />
        <span v-if="timezoneObject">
            {{ timezoneObject.getLocalTime(this.currentLocale) }}
            ({{ timezoneObject.shortName }})
        </span>
        <span v-else> {{ trans('general.not_available') }}</span>
    </small>
</template>
<script>
import { Timezone } from '@/Utils/timezone';

export default {
    name: 'current-time',
    props: {
        timezone: String,
    },
    computed: {
        timezoneObject() {
            return this.timezone ? new Timezone(this.timezone) : null;
        },
    },
};
</script>
